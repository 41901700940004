import React, { useEffect } from "react";
import img from "../../assets/img/1.jpg";
import offer from "../../assets/img/home-offer.jpg";

import Breadcrumbs from "../../components/Breadcrumbs/Breadcrumbs";
import Btn from "../../components/Btn/Btn";
import Slider from "../../components/Slider/Slider";

function Home() {
  useEffect(() => {
    document.title = "Plast-Met";
  });

  return (
    <>
      <Breadcrumbs
        title="Plast-Met"
        subTitle="Przedsiębiorstwo produkcyjno-handlowo-usługowe"
      />
      <div className="home container-1600">
        <div className="home__about-us container-960">
          <h2>Kilka słów o nas</h2>
          <div className="home__about-us__image">
            <img src={img} alt="" role="presentation" loading="lazy" />
          </div>
          <div className="home__about-us__text">
            <p>
              Jesteśmy firmą działającą na polskim rynku od 1994 roku! Przez te
              kilkanaście lat staramy sie sprostać wymaganiom rynku, wciąż
              podnosząc jakosć naszych wyrobów!
            </p>
            <Btn link="/o-firmie" title="Dowiedz się więcej" />
          </div>
        </div>
        <div className="home__offer container-960">
          <h2>Poznaj nasze produkty</h2>
          <div className="home__offer__text">
            <p>
              W naszej ofercie przedstawiamy Państwu nity drążone: FI4, FI5,
              FI6;
            </p>
            <Btn link="/oferta" title="Zobacz pełną ofertę" />
          </div>
          <div className="home__offer__image">
            <Slider />
          </div>
        </div>
      </div>
    </>
  );
}

export default Home;
