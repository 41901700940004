import React from "react";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import ScrollToTop from "../../base/ScrollToTop";

import Header from "../Header/Header";
import Footer from "../Footer/Footer";
import Home from "../../pages/Home/Home";
import About from "../../pages/About/About";
import Offer from "../../pages/Offer/Offer";
import Cooperation from "../../pages/Cooperation/Cooperation";
import Contact from "../../pages/Contact/Contact";

function App() {
  return (
    <Router>
      <Header />
      <div>
        <Switch>
          <Route exact path="/">
            <Home title="Strona główna" />
          </Route>
          <Route path="/o-firmie">
            <About title="O firmie" />
          </Route>
          <Route path="/oferta">
            <Offer title="Oferta" />
          </Route>
          <Route path="/wspolpraca">
            <Cooperation title="Współpraca" />
          </Route>
          <Route path="/kontakt">
            <Contact title="Kontakt" />
          </Route>
        </Switch>
      </div>
      <Footer />
      <ScrollToTop />
    </Router>
  );
}

export default App;
