import React, { useEffect } from "react";

function Breadcrumbs(props) {
  useEffect(() => {
    const title = props.title;
    const subTitle = props.subTitle;
  }, [props.title, props.subTitle]);
  return (
    <div className="breadcrumbs">
      <div className="breadcrumbs__container container-1600">
        <h1>{props.title}</h1>
        <h2>{props.subTitle}</h2>
      </div>
    </div>
  );
}

export default Breadcrumbs;
