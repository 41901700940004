import React, { useEffect, useState } from "react";

import Breadcrumbs from "../../components/Breadcrumbs/Breadcrumbs";

function Contact(props) {
  useEffect(() => {
    document.title = `${props.title} - Plast-Met` || "";
  }, [props.title]);

  const [form, setForm] = useState({
    name: "",
    phone: "",
    email: "",
    message: "",
  });

  function getParams() {
    const params = {
      subject: `Wiadomość z formularza od ${form.name} <${form.email}>`,
      body: encodeURIComponent(`
        ${form.message}

        ---

        ${form.name} <${form.email}>
        ${form.phone}
      `),
    };

    return Object.keys(params)
      .map((key) => key + "=" + params[key])
      .join("&");
  }

  function submit(event) {
    event.preventDefault();
    window.location.href = `mailto:biuro@imoli.pl?${getParams()}`;
  }

  return (
    <>
      <Breadcrumbs title="Kontakt" subTitle="Skontaktuj się z nami" />
      <div className="contact container-960">
        <div className="contact__address">
          <p className="h4">Dane kontaktowe</p>
          <p>
            Przedsiębiorstwo <br /> Produkcyjno-Handlowo-Usługowe <br />
            Krzysztof Klama <br /> Nowiny ul.Leśna 55 <br /> 42-133 Węglowice
          </p>
          <a href="tel:+48600503960">600 503 960</a>
          <a href="tel:+48660200861">660 200 861</a>
          <a href="mailto:biuro@plast-met.info">biuro@plast-met.info</a>
        </div>
        <div className="contact__form">
          <p className="h4">Formularz kontaktowy</p>
          <form onSubmit={submit}>
            <label>
              <span>Imię i nazwisko</span>
              <input
                type="text"
                onInput={(event) =>
                  setForm({ ...form, name: event.target.value })
                }
                placeholder="Imię i nazwisko"
              />
            </label>
            <label>
              <span>Tel. kontaktowy</span>
              <input
                type="text"
                onInput={(event) =>
                  setForm({ ...form, phone: event.target.value })
                }
                placeholder="Telefon kontaktowy"
              />
            </label>
            <label>
              <span>Adres email</span>
              <input
                type="text"
                onInput={(event) =>
                  setForm({ ...form, email: event.target.value })
                }
                placeholder="Adres e-mail"
              />
            </label>
            <label>
              <span>Wiadomość</span>
              <textarea
                onInput={(event) =>
                  setForm({ ...form, message: event.target.value })
                }
                placeholder="Treść wiadomości"
              ></textarea>
            </label>
            <button className="btn" type="submit">
              Wyślij wiadomość
            </button>
          </form>
        </div>
        <div className="contact__map">
          <iframe
            src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d95925.23505045853!2d18.804687390590317!3d50.817741553071684!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x4710c09d1f7d1721%3A0x595ce233c46165b5!2sPLAST-MET!5e0!3m2!1spl!2spl!4v1597748654080!5m2!1spl!2spl"
            loading="lazy"
          ></iframe>
        </div>
      </div>
    </>
  );
}

export default Contact;
