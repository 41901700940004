import React, { useEffect } from "react";

import Breadcrumbs from "../../components/Breadcrumbs/Breadcrumbs";

function Cooperation(props) {
  useEffect(() => {
    document.title = `${props.title} - Plast-Met` || "";
  }, [props.title]);
  return (
    <>
      <Breadcrumbs
        title="Współpraca"
        subTitle="Kupuj od nas i współpracuj z nami"
      />
      <div className="cooperation container-960">
        <p>
          Zapraszamy do współpracy firmy produkujące wózki dziecięce,
          producentów mebli ogrodowych oraz nadstawek paletowych. Dla odbiorców
          hurtowych gwarantujemy rabaty i stabilne ceny. Wszystkie zamówienia
          realizujemy w terminie. Każdego kontrahenta traktujemy poważnie i z
          szacunkiem.
        </p>
        <p>Realizujemy zamówienia na nietypowe rozmiary śrub i wkrętów.</p>
        <p>Zainteresowane firmy prosimy o kontakt.</p>
      </div>
    </>
  );
}

export default Cooperation;
