import one from "../../assets/img/gallery/01.jpg";
import two from "../../assets/img/gallery/02.jpg";
import three from "../../assets/img/gallery/03.jpg";
import four from "../../assets/img/gallery/04.jpg";
import five from "../../assets/img/gallery/05.jpg";
import six from "../../assets/img/gallery/06.jpg";
import seven from "../../assets/img/gallery/07.jpg";
import eight from "../../assets/img/gallery/08.jpg";
import nine from "../../assets/img/gallery/09.jpg";
import ten from "../../assets/img/gallery/10.jpg";
import eleven from "../../assets/img/gallery/11.jpg";
import twelve from "../../assets/img/gallery/12.jpg";
import onethree from "../../assets/img/gallery/13.jpg";
import onefour from "../../assets/img/gallery/14.jpg";
import onefive from "../../assets/img/gallery/15.jpg";
import onesix from "../../assets/img/gallery/16.jpg";
import oneseven from "../../assets/img/gallery/17.jpg";
import oneeight from "../../assets/img/gallery/18.jpg";
import onenine from "../../assets/img/gallery/19.jpg";
import twenty from "../../assets/img/gallery/20.jpg";
import twoone from "../../assets/img/gallery/21.jpg";
import twotwo from "../../assets/img/gallery/22.jpg";

const photos = [
  { src: one, width: 1, height: 1 },
  { src: two, width: 1, height: 1 },
  { src: three, width: 1, height: 1 },
  { src: four, width: 1, height: 1 },
  { src: five, width: 1, height: 1 },
  { src: six, width: 1, height: 1 },
  { src: seven, width: 1, height: 1 },
  { src: eight, width: 1, height: 1 },
  { src: nine, width: 1, height: 1 },
  { src: ten, width: 1, height: 1 },
  { src: eleven, width: 1, height: 1 },
  { src: twelve, width: 1, height: 1 },
  { src: onethree, width: 1, height: 1 },
  { src: onethree, width: 1, height: 1 },
  { src: onefour, width: 1, height: 1 },
  { src: onefive, width: 1, height: 1 },
  { src: onesix, width: 1, height: 1 },
  { src: oneseven, width: 1, height: 1 },
  { src: oneeight, width: 1, height: 1 },
  { src: onenine, width: 1, height: 1 },
  { src: twenty, width: 1, height: 1 },
  { src: twoone, width: 1, height: 1 },
  { src: twotwo, width: 1, height: 1 },
];

export default photos;
