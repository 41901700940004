import React, { useEffect } from "react";

import Breadcrumbs from "../../components/Breadcrumbs/Breadcrumbs";
import GalleryOffer from "../../components/GalleryOffer/GalleryOffer";

function Offer(props) {
  useEffect(() => {
    document.title = `${props.title} - Plast-Met` || "";
  }, [props.title]);
  return (
    <>
      <Breadcrumbs title="Oferta" subTitle="Obejrzyj i zamów nasze produkty" />
      <div className="offer container-960">
        <div className="offer__text">
          <p>
            Firma zajmuje się produkcją elementów złącznych wykonywanych metodą
            tłoczenia na zimno. Opracowaliśmy technologię tłoczenia nitów
            drążonych, które znalazły zastosowanie do produkcji wózków
            dziecięcych. Nity drążone są wykorzystywane także w nadstawkach
            paletowych i przy produkcji mebli ogrodowych. Posiadamy odpowiedni
            park maszynowy i jesteśmy gotowi zrealizować zamówienia nawet na
            nietypowe rozmiary śrub i wkrętów do metalu.
            <br /> <br />
            Produkujemy nity, drążone, nity pełne, nity dwustopniowe, wkręty do
            metalu i śruby.
          </p>
        </div>
        <div className="offer__gallery">
          <GalleryOffer />
        </div>
      </div>
    </>
  );
}

export default Offer;
