import React from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import SwiperCore, { Autoplay } from "swiper";

import "swiper/swiper.scss";

import slide1 from "../../assets/img/slider1.jpg";
import slide2 from "../../assets/img/slider2.jpg";
import slide3 from "../../assets/img/slider3.jpg";

SwiperCore.use([Autoplay]);

function Slider() {
  return (
    <Swiper slidesPerView={1} autoplay loop>
      <SwiperSlide>
        <img src={slide1} alt="" role="presentation" loading="lazy" />
      </SwiperSlide>
      <SwiperSlide>
        <img src={slide2} alt="" role="presentation" loading="lazy" />
      </SwiperSlide>
      <SwiperSlide>
        <img src={slide3} alt="" role="presentation" loading="lazy" />
      </SwiperSlide>
    </Swiper>
  );
}

export default Slider;
