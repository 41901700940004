import React, { useEffect } from "react";
import { Link } from "react-router-dom";

function Btn(props) {
  useEffect(() => {
    const link = props.link;
    const title = props.title;
  }, [props.title, props.link]);
  return (
    <Link to={props.link} className="btn">
      {props.title}
    </Link>
  );
}

export default Btn;
