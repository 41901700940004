import React from "react";

import MobileNav from "../MobileNav/MobileNav";

function Hamburger() {
  return (
    <div className="outer-menu">
      <input className="checkbox-toggle" type="checkbox" />
      <div className="hamburger">
        <div></div>
      </div>
      <MobileNav />
    </div>
  );
}

export default Hamburger;
