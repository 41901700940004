import React, { useEffect } from "react";

import Breadcrumbs from "../../components/Breadcrumbs/Breadcrumbs";

function About(props) {
  useEffect(() => {
    document.title = `${props.title} - Plast-Met` || "Plast-Met";
  }, [props.title]);
  return (
    <>
      <Breadcrumbs
        title="O firmie"
        subTitle="Kilka słów o naszej działalności"
      />
      <div className="about container-960">
        <div className="about__content">
          <div className="about__content__top">
            <p className="h5">
              Jesteśmy firmą działającą na polskim rynku od 1994 roku! Przez te
              kilkanaście lat staramy sie sprostać wymaganiom rynku,wciąż
              podnosząc jakosć naszych wyrobów! W naszej ofercie znajdą państwo:
              NITY, NITY DRĄŻONE, ŚRUBY, WKRĘTY DO METALU. Cechuje nas solidna i
              terminowa forma dostaw towaru!
            </p>
          </div>
          <div className="about__content__bottom">
            <div className="about__content__bottom__left">
              <p>
                Plast-met istnieje na rynku od 1994 roku, jednak nasze
                doświadczenie w obróbce plastycznej i obróbce skrawaniem sięga
                lat osiemdziesiątych. Przez wiele lat zmieniającej się sytuacji
                na rynku nasza firma ciągle dostosowuje swoją ofertę dla potrzeb
                rynku
              </p>
              <p>
                Nasza oferta jest poszerzana o wykonywane na zlecenie nity,
                śruby bądź wkręty. Wiądącym produktem firmy są nity drążone,
                które ze względu na swoją jakość są wykrzystywane w wielu
                gałęziach przemysłu metalowego.
              </p>
            </div>
            <div className="about__content__bottom__right">
              <p>
                Dla zapewnienia ciągłego wzrostu jakości naszych produktów oraz
                usług systematycznie powiększamy oraz unowocześniamy park
                maszynowy. Posiadamy niezbędną wiedzę oraz doświadczenie
                pozwalające udoskonalać oraz wprowadzać nowe rozwiązania, tak
                aby nasze produkty spełniały oczekiwania odbiorców.
              </p>
              <p>
                Atutami naszej firmy są:
                <ul>
                  <li>jakość wyrobów,</li>
                  <li>terminowość realizacjo zamówień,</li>
                  <li>rozsądna i stabilna centa.</li>
                </ul>
                Serdecznie zapraszamy do zapoznania się z ofertą naszej firmy.
              </p>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default About;
