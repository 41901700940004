import React from "react";
import { Link } from "react-router-dom";
import Phone from "@material-ui/icons/Phone";
import Email from "@material-ui/icons/Email";

import logo from "../../assets/img/logo.png";

import Nav from "../Nav/Nav";
import Hamburger from "../Hamburger/Hamburger";
// import MobileNav from "../MobileNav/MobileNav";

function Header() {
  return (
    <div className="header">
      <div className="header__container container-1600">
        <div className="header__container__logo">
          <Link to="/">
            <img src={logo} alt="Logo" loading="lazy" />
          </Link>
        </div>
        <div className="header__right-column">
          <Nav />
          <div className="header__right-column__contact">
            <a href="tel:+48600503960">
              <Phone fontSize="large" />
              600-503-960
            </a>
            <a href="mailto:biuro@plast-met.info">
              <Email fontSize="large" /> biuro@plast-met.info
            </a>
          </div>
        </div>
        <Hamburger />
      </div>
    </div>
  );
}

export default Header;
