import React from "react";
import { Link } from "react-router-dom";

function MobileNav() {
  return (
    <nav className="mobile-nav">
      <div>
        <ul>
          <li>
            <Link to="/">Strona główna</Link>
          </li>
          <li>
            <Link to="/o-firmie">O firmie</Link>
          </li>
          <li>
            <Link to="/oferta">Oferta</Link>
          </li>
          <li>
            <Link to="/wspolpraca">Współpraca</Link>
          </li>
          <li>
            <Link to="/kontakt">Kontakt</Link>
          </li>
        </ul>
      </div>
    </nav>
  );
}

export default MobileNav;
