import React from "react";
import { Link } from "react-router-dom";
import image from "../../assets/img/rzetelna.png";

function Footer() {
  return (
    <div className="footer">
      <div className="footer__container container-1600">
        <div className="footer__container__content">
          <div className="footer__container__content__about">
            <p className="h4">O naszej firmie</p>
            <p>
              Jesteśmy firmą działającą na polskim rynku od 1994 roku! Przez te
              kilkanaście lat staramy sie sprostać wymaganiom rynku,wciąż
              podnosząc jakosć naszych wyrobów!
            </p>
            <Link to="/o-firmie">Czytaj dalej</Link>
          </div>
          <div className="footer__container__content__address">
            <p className="h4">Dane kontaktowe</p>
            <p>
              Przedsiębiorstwo <br /> Produkcyjno-Handlowo-Usługowe <br />
              Krzysztof Klama <br /> Nowiny ul.Leśna 55 <br /> 42-133 Węglowice
            </p>
            <a href="tel:+48600503960">600 503 960</a>
            <a href="tel:+48660200861">660 200 861</a>
            <a href="mailto:biuro@plast-met.info">biuro@plast-met.info</a>
          </div>
          <div className="footer__container__content__menu">
            <p className="h4">Nawigacja</p>
            <ul>
              <li>
                <Link to="/">Strona główna</Link>
              </li>
              <li>
                <Link to="/o-firmie">O firmie</Link>
              </li>
              <li>
                <Link to="/oferta">Oferta</Link>
              </li>
              <li>
                <Link to="/wspolpraca">Współpraca</Link>
              </li>
              <li>
                <Link to="/kontakt">Kontakt</Link>
              </li>
            </ul>
          </div>
          <div className="footer__container__content__company">
            <a
              href="https://wizytowka.rzetelnafirma.pl/BHC17G3P"
              target="_blank"
              rel="nofollow noopener noreferrer"
            >
              <img src={image} alt="Rzetelna firma" loading="lazy" />
            </a>
          </div>
        </div>
        <div className="footer__container__copyright">
          <p>
            Copyright {new Date().getFullYear()} by Przedsiębiorstwo
            Produkcyjno-Handlowo-Usługowe "PLAST-MET
          </p>
          <p>
            Projekt i wykonanie: <a href="https://imoli.pl/">Imoli</a>
          </p>
        </div>
      </div>
    </div>
  );
}

export default Footer;
